<template>
	<base-container>
		<base-row>
			<base-col col="12">
				<div class="wrapper">
					<divider-heading alignCenter>{{langs.title}}</divider-heading>
					<div class="info-wrapper">
						<base-font>
							{{langs.redirect}} <span class="counter">{{counter}}</span>
						</base-font>
						<base-font>
							{{langs.manual_redirect}}
						</base-font>
						<base-button to="homepage" class="back-btn"  type="white">
							{{langs.back_btn}}
						</base-button>
					</div>
				</div>
			</base-col>
		</base-row>
	</base-container>
</template>
<script>
import DividerHeading from '~/website/front/components/molecules/DividerHeading'
export default {
	components: {
		DividerHeading
	},
	data: () => ({
		counter: 10
	}),
	computed: {
		langs () {
			return this.$app.translator.get('crossing-page')
		}
	},
	methods: {
		startInterval () {
			this.timer = setInterval(this.interval, 1000)
		},
		interval () {
			if (this.counter >= 1) this.counter--
			else this.$app.page.authRedirect()
		}
	},
	mounted () {
		this.startInterval()
	},
	beforeDestroy () {
		clearInterval(this.timer)
	}
}
</script>
<style lang="scss" scoped>
	.wrapper {
		margin: 3rem 0;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		@include media-breakpoint-up (lg) {
			margin: 7rem 0;
		}
		.info-wrapper {
			margin: 3rem auto;
			@include media-breakpoint-up(lg) {
				width: 40%;
			}
		}
		.counter {
			font-weight: 700;
			color: $gold-600;
		}
		:deep(.back-btn)  {
			margin: 3rem auto 0 auto;
			max-width: 330px;
			justify-content: center;
		}
	}
</style>
